<template>
  <div>
    <div v-show="visible" class="info">
      <top-bar
        :title="
          !submitData.id
            ? '新增安全责任险'
            : edit
            ? '修改安全责任险'
            : '查看安全责任险'
        "
        :left="true"
      ></top-bar>
      <!-- 被保人类型 -->
      <van-popup v-model="addrShow" position="bottom">
        <van-picker
          title="请选择"
          show-toolbar
          :columns="selectData.insuredTypeList"
          value-key="label"
          @confirm="addrConfirm"
          @cancel="addrShow = false"
        />
      </van-popup>
      <!-- 选择房屋 -->
      <van-popup v-model="houseShow" position="bottom">
        <van-cascader
          v-model="submitData.houseId"
          title="请选择"
          :options="selectData.buildingHouseList"
          @close="houseShow = false"
          @finish="houseFinish"
          :field-names="fieldNames"
        />
      </van-popup>
      <!-- 投保日期 -->
      <van-popup v-model="insuredDateShow" position="bottom">
        <van-datetime-picker
          v-model="submitData.insuredDate"
          type="datetime"
          title="请选择"
          :formatter="formatDate"
          @cancel="insuredDateShow = false"
          @confirm="timeConfirm"
        />
      </van-popup>

      <van-cell-group>
        <van-cell class="info-title">
          <template #title>
            <div class="blue-box"></div>
            <span class="custom-title">{{
              !submitData.id
                ? "新增安全责任险"
                : edit
                ? "修改安全责任险"
                : "查看安全责任险"
            }}</span>
          </template>
        </van-cell>
        <van-cell
          title="被保人类型"
          :value="selectMsg.insuredTypeStr"
          @click="addrShow = edit"
          :clickable="edit"
          :value-class="{
            'value-common': selectMsg.insuredTypeStr == '请选择',
          }"
          :is-link="edit"
        />
        <van-cell
          title="住宅房屋"
          :value="selectMsg.insuredName"
          @click="houseShow = edit"
          :clickable="edit"
          v-if="submitData.insuredType == '1'"
          :value-class="{ 'value-common': selectMsg.insuredName == '请选择' }"
          :is-link="edit"
        />
        <van-cell
          :title="edit ? '选择场所' : '场所'"
          :value="selectMsg.insuredName"
          @click="selectPlace"
          v-if="submitData.insuredType == '2'"
          :clickable="edit"
          :value-class="{ 'value-common': selectMsg.insuredName == '请选择' }"
          :is-link="edit"
        />
        <van-cell title="保费">
          <van-field
            clearable
            clear-trigger="always"
            v-model="submitData.fee"
            :readonly="!edit"
            placeholder="请输入"
          />
        </van-cell>
        <van-cell title="面积">
          <van-field
            clearable
            clear-trigger="always"
            v-model="submitData.area"
            :readonly="!edit"
            placeholder="请输入"
          />
        </van-cell>
        <van-cell title="联系电话">
          <van-field
            clearable
            clear-trigger="always"
            v-model="submitData.mobile"
            :readonly="!edit"
            placeholder="请输入"
          />
        </van-cell>
        <van-cell
          title="投保日期"
          :value="selectMsg.insuredtime"
          @click="insuredDateShow = edit"
          :value-class="{
            'value-common': selectMsg.insuredtime == '请选择',
          }"
          :is-link="edit"
        />
      </van-cell-group>

      <van-row class="btns">
        <van-col :span="24" v-if="edit">
          <van-button
            type="info"
            size="large"
            :loading="loading"
            round
            @click="submit"
            >完成</van-button
          >
        </van-col>
        <van-row gutter="10" v-else>
          <!-- <van-col :span="12">
            <van-button
              plain
              type="danger"
              size="large"
              round
              @click="deleteHandle"
              style="background-color: transparent"
              >删除</van-button
            >
          </van-col> -->
          <van-col :span="24">
            <van-button type="info" size="large" round @click="edit = true"
              >编辑</van-button
            >
          </van-col>
        </van-row>
      </van-row>
    </div>
    <place-list v-if="placeListVisible" @checkPlace="checkPlace"></place-list>
  </div>
</template>

<script>
import topBar from "@/components/topBar/topBar";
import placeList from "../visit/placeList";
import { beforeFileUpload, formatterDateTimeWithOutSec } from "@/utils/utils";
export default {
  components: {
    topBar,
    placeList,
  },
  data() {
    return {
      id: "",
      visible: true,
      edit: false,
      loading: false,
      addrShow: false,
      buildingTypeShow: false,
      houseShow: false,
      placeListVisible: false,
      insuredDateShow: false,
      fieldNames: {
        text: "label",
        value: "value",
        children: "children",
      },
      selectMsg: {
        insuredTypeStr: "请选择",
        houseTitle: "请选择",
        publicBuildingStr: "请选择",
        placeStr: "请选择",
        subareaStr: "请选择",
        inspectionTypeStr: "请选择",
        insuredtime: "请选择",
        insuredDate: "",
        insuredName: "请选择",
      },
      submitData: {
        id: "",
        addrId: "",
        insuredDate: "",
        insuredType: "",
        fee: "",
        area: "",
        mobile: "",
        houseId: "",
        address: "",
        insured: "",
      },
      selectData: {
        insuredTypeList: [
          { label: "居民房屋", value: "1" },
          { label: "商户", value: "2" },
        ],
        //房屋集合
        buildingHouseList: [],
        buildingHouseValueList: [],
        //商户集合
        placeList: [],
      },
    };
  },
  methods: {
    // 初始化
    init() {
      this.getBuildingList();
      let nowDate = new Date();
      this.maxDate = nowDate;
      if (this.submitData.id) {
        this.edit = false;
        this.getInfo();
      } else {
        this.edit = true;
      }
    },
    // 获取房屋列表
    getBuildingList() {
      this.$http({
        url: this.$http.adornUrl("/wxapp/building/house/liveWhichTree"),
        method: "get",
        params: this.$http.adornParams({
          communityId: this.$orgId,
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.selectData.buildingHouseList = data.liveWhichTree;
        } else {
          this.$toast.fail(data.msg);
        }
      });
    },
    getInfo() {
      this.$http({
        url: this.$http.adornUrl("/wxapp/insured/info/" + this.submitData.id),
        method: "post",
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log(data);
          this.submitData = data.appInsured;
          this.selectMsg.insuredtime = formatterDateTimeWithOutSec(
            data.appInsured.insuredDate
          );

          this.selectMsg.insuredTypeStr = data.appInsured.insuredTypeStr;
          this.selectMsg.insuredName = data.appInsured.address;
        } else {
          this.$toast.fail(data.msg);
        }
      });
    },
    // deleteHandle() {
    //   this.$dialog
    //     .confirm({
    //       title: "确定删除吗？",
    //     })
    //     .then(() => {
    //       this.$http({
    //         url: this.$http.adornUrl("/wxapp/insured/delete"),
    //         method: "post",
    //         params: this.$http.adornParams({
    //           ids: [parseInt(this.submitData.id)],
    //         }),
    //       }).then(({ data }) => {
    //         if (data && data.code === 0) {
    //           this.$toast.success({
    //             message: "删除成功",
    //             duration: 3000,
    //             onOpened: () => {
    //               setTimeout(() => {
    //                 this.$router.go(-1);
    //               }, 1500);
    //             },
    //           });
    //         } else {
    //           this.$toast.fail(data.msg);
    //         }
    //       });
    //     });
    // },
    // 提交
    submit() {
      if (this.submitData.insuredType === "") {
        return this.$toast.fail("失败:请选择被保人类型");
      } else {
        if (this.submitData.insuredType == 1) {
          if (this.submitData.houseId === "") {
            return this.$toast.fail("失败:请选择投保房屋");
          }
        } else if (this.submitData.insuredType == 2) {
          if (this.submitData.insured === "") {
            return this.$toast.fail("失败:请选择投保商户");
          }
        } else {
          return this.$toast.fail("失败:被保人类型选择失败");
        }
      }
      if (this.submitData.fee === "") {
        return this.$toast.fail("失败:保费不能为空");
      }
      if (this.submitData.area === "") {
        return this.$toast.fail("失败:面积不能为空");
      }
      if (this.submitData.mobile === "") {
        return this.$toast.fail("失败:联系电话不能为空");
      }
      if (this.submitData.insuredDate === "") {
        return this.$toast.fail("失败:投保时间不能为空");
      }
      this.loading = true;
      this.$http({
        url: this.$http.adornUrl(`/wxapp/insured/save`),
        method: "post",
        data: {
          id: this.submitData.id,
          insuredType: this.submitData.insuredType,
          fee: this.submitData.fee,
          area: this.submitData.area,
          mobile: this.submitData.mobile,
          insuredDate: this.submitData.insuredDate,
          houseId:
            this.submitData.insuredType == "2"
              ? this.submitData.addrId
              : this.submitData.houseId,
          address: this.submitData.address,
          insured: this.submitData.insured ? this.submitData.insured : "0",
        },
      }).then(({ data }) => {
        if (data.code == 0) {
          this.$toast.success({
            message: `${this.submitData.id ? "修改成功" : "新增成功"}`,
            duration: 3000,
            onOpened: () => {
              setTimeout(() => {
                this.$router.go(-1);
              }, 1500);
            },
          });
        } else {
          this.loading = false;
          this.$toast.fail(data.msg);
        }
      });
      this.loading = false;
    },
    // 巡查地点选择
    addrConfirm(value) {
      if (value) {
        if (this.submitData.insuredType != value.value) {
          this.selectMsg.insuredName = "请选择";
        }
        this.selectMsg.insuredTypeStr = value.label;
        this.submitData.insuredType = value.value;
      }
      this.addrShow = false;
    },
    // 房屋选择
    houseFinish(value) {
      if (value) {
        let options = value.selectedOptions;
        this.submitData.addrId = options[options.length - 1].value;
        this.submitData.buildingId = options[1].value;
        this.submitData.subarea = options[0].value;
        this.submitData.detailedAddressValue = options.map((item) => {
          return item.value;
        });
        this.selectMsg.buildingStr = "";
        this.submitData.building = value.value;
        value.selectedOptions.map((item) => {
          this.selectMsg.buildingStr += item.label;
        });
        this.submitData.address = this.selectMsg.buildingStr;
        this.selectMsg.insuredName = this.selectMsg.buildingStr;
        this.isHaveHouseMaster(options[options.length - 1].value)
      }
      this.houseShow = false;
    },
    // 日期选项格式化
    formatDate(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      if (type === "hour") {
        return val + "时";
      }
      if (type === "minute") {
        return val + "分";
      }
      return val;
    },
    timeConfirm(value) {
      debugger;
      if (value) {
        this.selectMsg.insuredtime = formatterDateTimeWithOutSec(value);
        this.submitData.insuredDate = formatterDateTimeWithOutSec(value);
      }
      this.insuredDateShow = false;
    },
    // 选择场所
    selectPlace() {
      this.visible = false;
      this.placeListVisible = true;
    },
    // 选择完场所
    checkPlace(msg) {
      this.placeListVisible = false;
      this.visible = true;
      this.submitData.addrId = msg.addrId;
      this.submitData.address = msg.address;
      this.submitData.insured = msg.placeValue;
      this.selectMsg.insuredName = msg.placeTitle;
    },
    //查看该房屋下是否存在房主
    isHaveHouseMaster(e) {
      this.$http({
        url: this.$http.adornUrl("/wxapp/user/res/houseRes/getMaster"),
        method: "get",
        params: this.$http.adornParams({
          houseId: e,
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.submitData.mobile = data.userInfoEntity.mobile;
          this.submitData.insured = data.userInfoEntity.id;
        } else {
          this.$toast.fail("请先添加房主信息");
          this.submitData.houseId = "";
          this.selectMsg.houseTitle = "请选择";
          this.submitData.address = "";
          this.selectMsg.insuredName = ''
        }
      });
    },
  },
  created() {
    this.submitData.id = this.$route.query.id || "";
    document.querySelector("html").style.backgroundColor = "#fff";
    this.init();
  },
  beforeDestroy() {
    document.querySelector("html").style.backgroundColor = "";
  },
};
</script>
