var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],staticClass:"info"},[_c('top-bar',{attrs:{"title":!_vm.submitData.id
          ? '新增安全责任险'
          : _vm.edit
          ? '修改安全责任险'
          : '查看安全责任险',"left":true}}),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.addrShow),callback:function ($$v) {_vm.addrShow=$$v},expression:"addrShow"}},[_c('van-picker',{attrs:{"title":"请选择","show-toolbar":"","columns":_vm.selectData.insuredTypeList,"value-key":"label"},on:{"confirm":_vm.addrConfirm,"cancel":function($event){_vm.addrShow = false}}})],1),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.houseShow),callback:function ($$v) {_vm.houseShow=$$v},expression:"houseShow"}},[_c('van-cascader',{attrs:{"title":"请选择","options":_vm.selectData.buildingHouseList,"field-names":_vm.fieldNames},on:{"close":function($event){_vm.houseShow = false},"finish":_vm.houseFinish},model:{value:(_vm.submitData.houseId),callback:function ($$v) {_vm.$set(_vm.submitData, "houseId", $$v)},expression:"submitData.houseId"}})],1),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.insuredDateShow),callback:function ($$v) {_vm.insuredDateShow=$$v},expression:"insuredDateShow"}},[_c('van-datetime-picker',{attrs:{"type":"datetime","title":"请选择","formatter":_vm.formatDate},on:{"cancel":function($event){_vm.insuredDateShow = false},"confirm":_vm.timeConfirm},model:{value:(_vm.submitData.insuredDate),callback:function ($$v) {_vm.$set(_vm.submitData, "insuredDate", $$v)},expression:"submitData.insuredDate"}})],1),_c('van-cell-group',[_c('van-cell',{staticClass:"info-title",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"blue-box"}),_c('span',{staticClass:"custom-title"},[_vm._v(_vm._s(!_vm.submitData.id ? "新增安全责任险" : _vm.edit ? "修改安全责任险" : "查看安全责任险"))])]},proxy:true}])}),_c('van-cell',{attrs:{"title":"被保人类型","value":_vm.selectMsg.insuredTypeStr,"clickable":_vm.edit,"value-class":{
          'value-common': _vm.selectMsg.insuredTypeStr == '请选择',
        },"is-link":_vm.edit},on:{"click":function($event){_vm.addrShow = _vm.edit}}}),(_vm.submitData.insuredType == '1')?_c('van-cell',{attrs:{"title":"住宅房屋","value":_vm.selectMsg.insuredName,"clickable":_vm.edit,"value-class":{ 'value-common': _vm.selectMsg.insuredName == '请选择' },"is-link":_vm.edit},on:{"click":function($event){_vm.houseShow = _vm.edit}}}):_vm._e(),(_vm.submitData.insuredType == '2')?_c('van-cell',{attrs:{"title":_vm.edit ? '选择场所' : '场所',"value":_vm.selectMsg.insuredName,"clickable":_vm.edit,"value-class":{ 'value-common': _vm.selectMsg.insuredName == '请选择' },"is-link":_vm.edit},on:{"click":_vm.selectPlace}}):_vm._e(),_c('van-cell',{attrs:{"title":"保费"}},[_c('van-field',{attrs:{"clearable":"","clear-trigger":"always","readonly":!_vm.edit,"placeholder":"请输入"},model:{value:(_vm.submitData.fee),callback:function ($$v) {_vm.$set(_vm.submitData, "fee", $$v)},expression:"submitData.fee"}})],1),_c('van-cell',{attrs:{"title":"面积"}},[_c('van-field',{attrs:{"clearable":"","clear-trigger":"always","readonly":!_vm.edit,"placeholder":"请输入"},model:{value:(_vm.submitData.area),callback:function ($$v) {_vm.$set(_vm.submitData, "area", $$v)},expression:"submitData.area"}})],1),_c('van-cell',{attrs:{"title":"联系电话"}},[_c('van-field',{attrs:{"clearable":"","clear-trigger":"always","readonly":!_vm.edit,"placeholder":"请输入"},model:{value:(_vm.submitData.mobile),callback:function ($$v) {_vm.$set(_vm.submitData, "mobile", $$v)},expression:"submitData.mobile"}})],1),_c('van-cell',{attrs:{"title":"投保日期","value":_vm.selectMsg.insuredtime,"value-class":{
          'value-common': _vm.selectMsg.insuredtime == '请选择',
        },"is-link":_vm.edit},on:{"click":function($event){_vm.insuredDateShow = _vm.edit}}})],1),_c('van-row',{staticClass:"btns"},[(_vm.edit)?_c('van-col',{attrs:{"span":24}},[_c('van-button',{attrs:{"type":"info","size":"large","loading":_vm.loading,"round":""},on:{"click":_vm.submit}},[_vm._v("完成")])],1):_c('van-row',{attrs:{"gutter":"10"}},[_c('van-col',{attrs:{"span":24}},[_c('van-button',{attrs:{"type":"info","size":"large","round":""},on:{"click":function($event){_vm.edit = true}}},[_vm._v("编辑")])],1)],1)],1)],1),(_vm.placeListVisible)?_c('place-list',{on:{"checkPlace":_vm.checkPlace}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }